/* eslint no-console: 0 */
import * as types from '../mutation-types';
import Report from '../../api/reports';
import { downloadCsvFile, generateFileName } from '../../helper/downloadHelper';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { REPORTS_EVENTS } from '../../helper/AnalyticsHelper/events';
import {
  reconcileHeatmapData,
  clampDataBetweenTimeline,
} from 'shared/helpers/ReportsDataHelper';

const state = {
  fetchingStatus: false,
  accountReport: {
    isFetching: {
      conversations_count: false,
      incoming_messages_count: false,
      outgoing_messages_count: false,
      avg_first_response_time: false,
      avg_resolution_time: false,
      resolutions_count: false,
      bot_resolutions_count: false,
      bot_handoffs_count: false,
      reply_time: false,
    },
    data: {
      conversations_count: [],
      incoming_messages_count: [],
      outgoing_messages_count: [],
      avg_first_response_time: [],
      avg_resolution_time: [],
      resolutions_count: [],
      bot_resolutions_count: [],
      bot_handoffs_count: [],
      reply_time: [],
    },
  },
  accountSummary: {
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    incoming_messages_count: 0,
    outgoing_messages_count: 0,
    reply_time: 0,
    resolutions_count: 0,
    bot_resolutions_count: 0,
    bot_handoffs_count: 0,
    previous: {},
  },
  botSummary: {
    bot_resolutions_count: 0,
    bot_handoffs_count: 0,
    previous: {},
  },
  overview: {
    uiFlags: {
      isFetchingAccountConversationMetric: false,
      isFetchingAccountConversationsHeatmap: false,
      isFetchingAgentConversationMetric: false,
    },
    accountConversationMetric: {},
    accountConversationHeatmap: [],
    agentConversationMetric: [],
    agentConversationMetricTotalCount: 0,
  },
  contact: {
    uiFlags: {
      isFetchingAccountContactMetric: false,
      isFetchingAgentContactMetric: false,
    },
    accountContactMetric: {},
    agentContactMetric: [],
    agentContactMetricTotalCount: 0,
  },
  conversion: {
    uiFlags: {
      isFetchingTeamConversionMetric: false,
      isFetchingDataSourceConversionMetric: false,
      isFetchingAgentConversionMetric: false,
      isFetchingInboxConversionMetric: false,
    },
    teamConversionMetric: [],
    teamConversionMetricTotalCount: 0,
    dataSourceConversionMetric: [],
    dataSourceConversionMetricTotalCount: 0,
    agentConversionMetric: [],
    agentConversionMetricTotalCount: 0,
    inboxConversionMetric: [],
    inboxConversionMetricTotalCount: 0,
  },
};

const getters = {
  getAccountReports(_state) {
    return _state.accountReport;
  },
  getAccountSummary(_state) {
    return _state.accountSummary;
  },
  getBotSummary(_state) {
    return _state.botSummary;
  },
  getAccountConversationMetric(_state) {
    return _state.overview.accountConversationMetric;
  },
  getAccountConversationHeatmapData(_state) {
    return _state.overview.accountConversationHeatmap;
  },
  getAgentConversationMetric(_state) {
    return _state.overview.agentConversationMetric;
  },
  getAgentConversationMetricTotalCount(_state) {
    return _state.overview.agentConversationMetricTotalCount;
  },
  getOverviewUIFlags($state) {
    return $state.overview.uiFlags;
  },
  getAccountContactMetric(_state) {
    return _state.contact.accountContactMetric;
  },
  getAgentContactMetric(_state) {
    return _state.contact.agentContactMetric;
  },
  getAgentContactMetricTotalCount(_state) {
    return _state.contact.agentContactMetricTotalCount;
  },
  getContactUIFlags($state) {
    return $state.contact.uiFlags;
  },
  getTeamConversionMetric(_state) {
    return _state.conversion.teamConversionMetric;
  },
  getTeamConversionMetricTotalCount(_state) {
    return _state.conversion.teamConversionMetricTotalCount;
  },
  getDataSourceConversionMetric(_state) {
    return _state.conversion.dataSourceConversionMetric;
  },
  getDataSourceConversionMetricTotalCount(_state) {
    return _state.conversion.dataSourceConversionMetricTotalCount;
  },
  getAgentConversionMetric(_state) {
    return _state.conversion.agentConversionMetric;
  },
  getAgentConversionMetricTotalCount(_state) {
    return _state.conversion.agentConversionMetricTotalCount;
  },
  getInboxConversionMetric(_state) {
    return _state.conversion.inboxConversionMetric;
  },
  getInboxConversionMetricTotalCount(_state) {
    return _state.conversion.inboxConversionMetricTotalCount;
  },
  getConversionUIFlags($state) {
    return $state.conversion.uiFlags;
  },
};

export const actions = {
  fetchAccountReport({ commit }, reportObj) {
    const { metric } = reportObj;
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
      metric,
      value: true,
    });
    Report.getReports(reportObj).then(accountReport => {
      let { data } = accountReport;
      data = clampDataBetweenTimeline(data, reportObj.from, reportObj.to);
      commit(types.default.SET_ACCOUNT_REPORTS, {
        metric,
        data,
      });
      commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, {
        metric,
        value: false,
      });
    });
  },
  fetchAccountConversationHeatmap({ commit }, reportObj) {
    commit(types.default.TOGGLE_HEATMAP_LOADING, true);
    Report.getReports({ ...reportObj, groupBy: 'hour' }).then(heatmapData => {
      let { data } = heatmapData;
      data = clampDataBetweenTimeline(data, reportObj.from, reportObj.to);

      data = reconcileHeatmapData(
        data,
        state.overview.accountConversationHeatmap
      );

      commit(types.default.SET_HEATMAP_DATA, data);
      commit(types.default.TOGGLE_HEATMAP_LOADING, false);
    });
  },
  fetchAccountSummary({ commit }, reportObj) {
    Report.getSummary(
      reportObj.from,
      reportObj.to,
      reportObj.id,
      reportObj.team?.id,
      reportObj.groupBy,
      reportObj.businessHours,
      reportObj.type
    )
      .then(accountSummary => {
        commit(types.default.SET_ACCOUNT_SUMMARY, accountSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  fetchBotSummary({ commit }, reportObj) {
    Report.getBotSummary({
      from: reportObj.from,
      to: reportObj.to,
      groupBy: reportObj.groupBy,
      businessHours: reportObj.businessHours,
    })
      .then(botSummary => {
        commit(types.default.SET_BOT_SUMMARY, botSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  fetchAccountConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric({
      type: reportObj.type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
    })
      .then(accountConversationMetric => {
        commit(
          types.default.SET_ACCOUNT_CONVERSATION_METRIC,
          accountConversationMetric.data
        );
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  fetchAgentConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric({
      type: reportObj.type,
      page: reportObj.page,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
    })
      .then(agentConversationMetric => {
        commit(
          types.default.SET_AGENT_CONVERSATION_METRIC,
          agentConversationMetric.data.records
        );
        commit(
          types.default.SET_AGENT_CONVERSATION_METRIC_TOTAL_COUNT,
          agentConversationMetric.data.total_count
        );
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  fetchAccountContactMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_CONTACT_METRIC_LOADING, true);
    Report.getContactMetric({
      type: reportObj.type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
    })
      .then(accountContactMetric => {
        commit(
          types.default.SET_ACCOUNT_CONTACT_METRIC,
          accountContactMetric.data
        );
        commit(types.default.TOGGLE_ACCOUNT_CONTACT_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_CONTACT_METRIC_LOADING, false);
      });
  },
  fetchAgentContactMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONTACT_METRIC_LOADING, true);
    Report.getContactMetric({
      type: reportObj.type,
      page: reportObj.page,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
    })
      .then(agentContactMetric => {
        commit(
          types.default.SET_AGENT_CONTACT_METRIC_TOTAL_COUNT,
          agentContactMetric.data.total_count
        );
        commit(
          types.default.SET_AGENT_CONTACT_METRIC,
          agentContactMetric.data.records
        );
        commit(types.default.TOGGLE_AGENT_CONTACT_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONTACT_METRIC_LOADING, false);
      });
  },
  fetchTeamConversionReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_TEAM_CONVERSION_METRIC_LOADING, true);

    Report.getConversionMetric({
      page: reportObj.page,
      criteria_type: reportObj.criteria_type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
      stageType: reportObj.stageType?.value,
    })
      .then(metric => {
        commit(types.default.SET_TEAM_CONVERSION_METRIC, metric.data.records);
        commit(
          types.default.SET_TEAM_CONVERSION_METRIC_TOTAL_COUNT,
          metric.data.total_count
        );
        commit(types.default.TOGGLE_TEAM_CONVERSION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_TEAM_CONVERSION_METRIC_LOADING, false);
      });
  },
  fetchDataSourceConversionReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_DATA_SOURCE_CONVERSION_METRIC_LOADING, true);

    Report.getConversionMetric({
      page: reportObj.page,
      criteria_type: reportObj.criteria_type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
      stageType: reportObj.stageType?.value,
    })
      .then(metric => {
        commit(
          types.default.SET_DATA_SOURCE_CONVERSION_METRIC,
          metric.data.records
        );
        commit(
          types.default.SET_DATA_SOURCE_CONVERSION_METRIC_TOTAL_COUNT,
          metric.data.total_count
        );
        commit(
          types.default.TOGGLE_DATA_SOURCE_CONVERSION_METRIC_LOADING,
          false
        );
      })
      .catch(() => {
        commit(
          types.default.TOGGLE_DATA_SOURCE_CONVERSION_METRIC_LOADING,
          false
        );
      });
  },
  fetchAgentConversionReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONVERSION_METRIC_LOADING, true);

    Report.getConversionMetric({
      page: reportObj.page,
      criteria_type: reportObj.criteria_type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
      stageType: reportObj.stageType?.value,
    })
      .then(metric => {
        commit(types.default.SET_AGENT_CONVERSION_METRIC, metric.data.records);
        commit(
          types.default.SET_AGENT_CONVERSION_METRIC_TOTAL_COUNT,
          metric.data.total_count
        );
        commit(types.default.TOGGLE_AGENT_CONVERSION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONVERSION_METRIC_LOADING, false);
      });
  },
  fetchInboxConversionReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_INBOX_CONVERSION_METRIC_LOADING, true);

    Report.getConversionMetric({
      page: reportObj.page,
      criteria_type: reportObj.criteria_type,
      from: reportObj.from,
      to: reportObj.to,
      teamId: reportObj.team?.id,
      stageType: reportObj.stageType?.value,
    })
      .then(metric => {
        commit(types.default.SET_INBOX_CONVERSION_METRIC, metric.data.records);
        commit(
          types.default.SET_INBOX_CONVERSION_METRIC_TOTAL_COUNT,
          metric.data.total_count
        );
        commit(types.default.TOGGLE_INBOX_CONVERSION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_INBOX_CONVERSION_METRIC_LOADING, false);
      });
  },
  downloadAgentContactsReports(_, reportObj) {
    return Report.getAgentContactsReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'agent',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadConversionsReports(_, reportObj) {
    return Report.getConversionsReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: reportObj.criteriaKey,
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadAgentConversationsReports(_, reportObj) {
    return Report.getAgentConversationsReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'agent',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadAgentReports(_, reportObj) {
    return Report.getAgentReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'agent',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadLabelReports(_, reportObj) {
    return Report.getLabelReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'label',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadInboxReports(_, reportObj) {
    return Report.getInboxReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'inbox',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadTeamReports(_, reportObj) {
    return Report.getTeamReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'team',
          businessHours: reportObj?.businessHours,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadAccountConversationHeatmap(_, reportObj) {
    Report.getConversationTrafficCSV()
      .then(response => {
        downloadCsvFile(
          generateFileName({
            type: 'Conversation traffic',
            to: reportObj.to,
          }),
          response.data
        );

        AnalyticsHelper.track(REPORTS_EVENTS.DOWNLOAD_REPORT, {
          reportType: 'conversation_heatmap',
          businessHours: false,
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
};

const mutations = {
  [types.default.SET_ACCOUNT_REPORTS](_state, { metric, data }) {
    _state.accountReport.data[metric] = data;
  },
  [types.default.SET_HEATMAP_DATA](_state, heatmapData) {
    _state.overview.accountConversationHeatmap = heatmapData;
  },
  [types.default.TOGGLE_ACCOUNT_REPORT_LOADING](_state, { metric, value }) {
    _state.accountReport.isFetching[metric] = value;
  },
  [types.default.TOGGLE_HEATMAP_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationsHeatmap = flag;
  },
  [types.default.SET_ACCOUNT_SUMMARY](_state, summaryData) {
    _state.accountSummary = summaryData;
  },
  [types.default.SET_BOT_SUMMARY](_state, summaryData) {
    _state.botSummary = summaryData;
  },
  [types.default.SET_ACCOUNT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.accountConversationMetric = metricData;
  },
  [types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationMetric = flag;
  },
  [types.default.SET_AGENT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.agentConversationMetric = metricData;
  },
  [types.default.SET_AGENT_CONVERSATION_METRIC_TOTAL_COUNT](
    _state,
    metricData
  ) {
    // FIXME
    _state.overview.agentConversationMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAgentConversationMetric = flag;
  },
  [types.default.SET_ACCOUNT_CONTACT_METRIC](_state, metricData) {
    _state.contact.accountContactMetric = metricData;
  },
  [types.default.TOGGLE_ACCOUNT_CONTACT_METRIC_LOADING](_state, flag) {
    _state.contact.uiFlags.isFetchingAccountContactMetric = flag;
  },
  [types.default.SET_AGENT_CONTACT_METRIC](_state, metricData) {
    _state.contact.agentContactMetric = metricData;
  },
  [types.default.SET_AGENT_CONTACT_METRIC_TOTAL_COUNT](_state, metricData) {
    _state.contact.agentContactMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_AGENT_CONTACT_METRIC_LOADING](_state, flag) {
    _state.contact.uiFlags.isFetchingAgentContactMetric = flag;
  },
  [types.default.SET_TEAM_CONVERSION_METRIC](_state, metricData) {
    _state.conversion.teamConversionMetric = metricData;
  },
  [types.default.SET_TEAM_CONVERSION_METRIC_TOTAL_COUNT](_state, metricData) {
    _state.conversion.teamConversionMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_TEAM_CONVERSION_METRIC_LOADING](_state, flag) {
    _state.conversion.uiFlags.isFetchingTeamConversionMetric = flag;
  },
  [types.default.SET_DATA_SOURCE_CONVERSION_METRIC](_state, metricData) {
    _state.conversion.dataSourceConversionMetric = metricData;
  },
  [types.default.SET_DATA_SOURCE_CONVERSION_METRIC_TOTAL_COUNT](
    _state,
    metricData
  ) {
    _state.conversion.dataSourceConversionMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_DATA_SOURCE_CONVERSION_METRIC_LOADING](_state, flag) {
    _state.conversion.uiFlags.isFetchingDataSourceConversionMetric = flag;
  },
  [types.default.SET_AGENT_CONVERSION_METRIC](_state, metricData) {
    _state.conversion.agentConversionMetric = metricData;
  },
  [types.default.SET_AGENT_CONVERSION_METRIC_TOTAL_COUNT](_state, metricData) {
    _state.conversion.agentConversionMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_AGENT_CONVERSION_METRIC_LOADING](_state, flag) {
    _state.conversion.uiFlags.isFetchingAgentConversionMetric = flag;
  },
  [types.default.SET_INBOX_CONVERSION_METRIC](_state, metricData) {
    _state.conversion.inboxConversionMetric = metricData;
  },
  [types.default.SET_INBOX_CONVERSION_METRIC_TOTAL_COUNT](_state, metricData) {
    _state.conversion.inboxConversionMetricTotalCount = metricData;
  },
  [types.default.TOGGLE_INBOX_CONVERSION_METRIC_LOADING](_state, flag) {
    _state.conversion.uiFlags.isFetchingInboxConversionMetric = flag;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
